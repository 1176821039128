import { Component, OnInit, Input } from '@angular/core';
import { Track } from '../models/track.model';

@Component({
  selector: 'app-track[track]',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.css']
})

export class TrackComponent implements OnInit {

  @Input() track: Track;

  constructor() {
    this.track = new Track();
   }

  ngOnInit(): void {
  }
  floor(float: number) {
    return Math.floor(float);
  }
  duration(ms: number) {
    var min = Math.floor(ms/1000/60);
    var sec = Math.floor(ms/1000)%60;
    return min+":"+(sec<10?"0"+sec: sec);
  }
}
