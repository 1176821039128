<h2 class="title">Party: {{joinCode}}</h2>
<button (click)="logout()" class="button logout">
  <img src="assets/logout.png">
</button>
<button [routerLink]="['/search']" class="searchButton">
    <img src="assets/search.svg">
</button>
<qrcode [qrdata]="qrDestination"
        [errorCorrectionLevel]="'M'"
        [elementType]="'url'"
        (click)="toggleQR()"
        [ngClass]="{'expanded': qrExpanded }"></qrcode>
<a id="spotifyLink" href="https://www.spotify.com" target="_blank"><img src="assets/Spotify_Logo_RGB_Green.png" /></a>
<div style="position: relative;">
  <div *ngIf="iAmAdmin && partyStatus.device == null">
    <span i18n="@@Device.Selection">
        Start your Spotify app on any device, reload this page until the device is shown and then select it.<br>
        Always leave the Spotify app open to make sure that everything is in sync. When everything is ready, press the play button here on the first track.
    </span>
      <div *ngFor="let d of devices;" class="device">
        <button (click)="selectDevice(d.id)">{{d.name}}</button>
      </div>
    <button (click)="reload()" i18n="@@Reload">Reload</button>
  </div>
</div>
<div *ngFor="let track of tracks; trackBy: trackBy; let indexOfelement=index;"
     class="playlistTrack" id="track{{track.trackId}}">
    <app-track [track]="track"></app-track>
    <div *ngIf="iAmAdmin" class="adminButtons">
        <button (click)="adminTrackCommand(track.trackId, 0)" class="button" *ngIf="indexOfelement == 0 && track.currentlyPlaying == 1">
            <img src="assets/pause.png" class="voteIcon">
        </button>
        <button (click)="adminTrackCommand(track.trackId, 1)" class="button pulsating" *ngIf="indexOfelement == 0 && track.currentlyPlaying != 1 && partyStatus.device != null">
            <img src="assets/play.svg" class="voteIcon">
        </button>
        <button (click)="adminTrackCommand(track.trackId, 2)" class="button" *ngIf="indexOfelement == 0">
            <img src="assets/skip.svg" class="voteIcon">
        </button>
        <button (click)="adminTrackCommand(track.trackId, -1)" class="button" *ngIf="indexOfelement > 0">
            <img src="assets/close.svg" class="voteIcon">
        </button>
    </div>
    <div *ngIf="track.currentlyPlaying == 0" class="fullSize">
        <div class="button" [ngClass]="{'selected': ownVotes[track.trackId] == 1}">
            <button (click)="vote(track.trackId, 1)" class="voteButton">
                <img src="assets/thumb.svg" class="voteIcon" style="transform: rotate(0deg); filter: invert(53%) sepia(4%) saturate(5045%) hue-rotate(71deg) brightness(129%) contrast(92%)">
            </button>
        </div>
        <div class="button" [ngClass]="{'selected': ownVotes[track.trackId] == -1}">
            <button (click)="vote(track.trackId, -1)" class="voteButton">
                <img src="assets/thumb.svg" class="voteIcon" style="transform: rotate(180deg); filter: invert(51%) sepia(42%) saturate(7049%) hue-rotate(347deg) brightness(102%) contrast(101%);">
            </button>
        </div>
    </div>
</div>
<div class="playlistTrack">
    <button [routerLink]="['/search']" class="addTrack" i18n="@@Playlist.AddTrack">
        Add a new track to the playlist
    </button>
</div>
<div *ngIf="loading" class="screenOverlay">
  <img src="assets/loading.png" class="animated loading">
</div>
