import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PlaylistSelectionComponent } from './playlist-selection/playlist-selection.component';
import { PlaylistViewComponent } from './playlist-view/playlist-view.component';
import { TrackSearchComponent } from './track-search/track-search.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'playlistSelection', component: PlaylistSelectionComponent  },
  { path: 'party', component: PlaylistViewComponent },
  { path: 'search', component: TrackSearchComponent },
  { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
