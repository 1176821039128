<a href="http://open.spotify.com/track/{{track.trackId}}" target="_blank"><img class="image" src="{{track.image}}"></a>
<div class="other">
    <div class="trackArtist"><a href="http://open.spotify.com/track/{{track.trackId}}" target="_blank">{{track.artist}}</a></div>
    <div class="trackTitle"><a href="http://open.spotify.com/track/{{track.trackId}}" target="_blank">{{track.title}}</a></div>
    <div class="trackAlbum">{{track.album}}</div>
    <div class="trackVotes">{{track.voteSum}} <img src="assets/thumb.svg" class="voteIcon"></div>
    <div class="trackDuration">{{duration(track.duration_ms)}}</div>
    <div class="trackStartTime">{{track.startTime}}</div>
</div>
<div *ngIf="track.currentlyPlaying > 0" class="progressBar">
    <div class="progress" [ngStyle]="{ 'width': (track.progress*100)+'%'}"></div>
</div>
