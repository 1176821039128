import { Component, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { EventQueueService } from '../event-queue.service';
import { AppEvent, AppEventType } from '../models/appEvent';
import { Playlist } from '../models/playlist.model';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-playlist-selection',
  templateUrl: './playlist-selection.component.html',
  styleUrls: ['./playlist-selection.component.css']
})
export class PlaylistSelectionComponent implements OnInit {

  constructor(
    private api: ApiService,
    private eventQueue: EventQueueService,
    private sessionService: SessionService) { }

  playlists : Playlist[] = [];

  ngOnInit(): void {
    this.fetchPlaylists();
  }

  fetchPlaylists(): void {
    var myself = this;
    this.api.getAdminPlaylists().subscribe(x => {
      if (x != null) {
        this.playlists = x;
        if (x.length == 1) {
          myself.selectPlaylist(x[0].id);
        }
        if (x.length == 0) {
          myself.createPlaylist();
        }
      } else {
        console.log("Could not fetch playlists");
        console.log(x);
      }
    });
  }

  selectPlaylist(id: string): void {
      this.api.setPlaylist(id).subscribe(x => {
        this.sessionService.checkCurrentSession();
        this.eventQueue.dispatch(new AppEvent(AppEventType.UpdatePlaylist, null));
      });
  }
  createPlaylist(): void {
    this.api.createPlaylist().subscribe(x => {
      this.sessionService.checkCurrentSession();
      this.eventQueue.dispatch(new AppEvent(AppEventType.UpdatePlaylist, null));
    });
  }
}
