/**
 * Provides access to all backend APIs
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Track } from './models/track.model';
import { PartySession } from './models/partySession.model';
import { Playlist } from './models/playlist.model';
import { Vote } from './models/vote.model';
import {PartyStatus} from "./models/PartyStatus.model";
import {SpotifyDevice} from "./models/SpotifyDevice";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) {   }

  get(url: string): Observable<string> {
    return this.http.get<string>("/api/"+url);
  }
  getSession(): Observable<PartySession> {
    return this.http.get<PartySession>("/api/checkSession");
  }
  logout(): Observable<PartySession> {
    return this.http.get<PartySession>("/api/login/logout");
  }

  longPolling(): Observable<Object> {
    return this.http.get<Object>("/api/playlist/longPolling");
  }
  getPlaylist(): Observable<Track[]> {
    return this.http.get<Track[]>("/api/playlist/tracks");
  }
  getVotes(): Observable<Vote[]> {
    return this.http.get<Vote[]>("/api/playlist/votes");
  }
  getAdminPlaylists(): Observable<Playlist[]> {
    return this.http.get<Playlist[]>("/api/playlist/getAll");
  }
  setPlaylist(id: string): Observable<Object> {
    return this.http.get("/api/playlist/set?id="+id);
  }
  createPlaylist(): Observable<Object> {
    return this.http.get("/api/playlist/create");
  }

  searchTracks(query: string): Observable<Track[]> {
    return this.http.get<Track[]>("/api/track/search?query="+query);
  }
  recommend(): Observable<Track[]> {
    return this.http.get<Track[]>("/api/track/recommend");
  }
  addTrack(id: string): Observable<object> {
    return this.http.get("/api/track/add?id="+id);
  }
  voteForTrack(id: string, vote: number): Observable<object> {
    return this.http.get("/api/track/vote?id="+id+"&vote="+vote);
  }
  adminTrackCommand(id: string, command: number): Observable<object> {
    return this.http.get("/api/admin/trackCommand?id="+id+"&command="+command);
  }
  adminRegisterDevice(id: string): Observable<object> {
    console.log("Calling device register");
    return this.http.get("/api/admin/registerDevice?id="+id);
  }
  adminCheck(): Observable<PartyStatus> {
    return this.http.get<PartyStatus>("/api/admin/check");
  }
  adminGetDevices(): Observable<SpotifyDevice[]> {
    return this.http.get<SpotifyDevice[]>("/api/admin/getDevices");
  }

  adminHealthCheck(): Observable<object> {
    return this.http.get<object>("/api/admin/healthCheck");
  }
}
