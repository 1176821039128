import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../api.service';
import { Track } from '../models/track.model';

@Component({
  selector: 'app-track-search',
  templateUrl: './track-search.component.html',
  styleUrls: ['./track-search.component.css']
})
export class TrackSearchComponent implements OnInit {

  constructor(private api: ApiService, private router: Router) { }

  currentInput = "";
  searchResult : Track[] = [];
  recommendations : Track[] = [];
  requestTimer : number = 0;
  loading: boolean = false;

  ngOnInit(): void {
    this.updateSearch();
  }

  updateSearch(): void {
    window.clearTimeout(this.requestTimer);
    if (this.currentInput.length >= 3) {
      this.requestTimer = window.setTimeout(() => {
        this.api.searchTracks(this.currentInput).subscribe(x => {
          this.searchResult = x;
        });
      }, 200);
    }
    if (this.currentInput.length == 0) {
      this.api.recommend().subscribe(x => {
        this.recommendations = x;
      });
    }
  }

  addTrack(id: string): void {
    this.loading = true;
    this.api.addTrack(id).subscribe(x => {
      this.loading = false;
      console.log("Result?", x);
      this.router.navigate(["party"]);
    });
  }
}
