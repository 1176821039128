import {PartyStatus} from "../models/PartyStatus.model";

declare var confetti: any;
///  <reference types="@types/spotify-web-playback-sdk"/>
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService } from '../api.service';
import { EventQueueService } from '../event-queue.service';
import {AppEvent, AppEventType} from '../models/appEvent';
import { Track } from '../models/track.model';
import { Vote } from '../models/vote.model';
import { SessionService } from '../session.service';
import {SpotifyDevice} from "../models/SpotifyDevice";

@Component({
  selector: 'app-playlist-view',
  templateUrl: './playlist-view.component.html',
  styleUrls: ['./playlist-view.component.css']
})
export class PlaylistViewComponent implements OnInit {

  constructor(
    private api: ApiService,
    private sessionService: SessionService,
    private eventQueue: EventQueueService
    ) { }

  joinCode : string = "";
  qrDestination : string = "";
  qrExpanded : boolean = false;
  tracks : Track[] = [];
  ownVotes: { [key: string]: number } = {};
  iAmAdmin : boolean = false;
  loading: boolean = false;
  useSpotifyAsPlayer : boolean = false;
  skipping : string = "";
  rnd : number = 0;
  sub : Subscription = new Subscription();
  checkInterval: number = 0;
  partyStatus: PartyStatus = new PartyStatus();
  devices: SpotifyDevice[] = [];
  firstTime: boolean = true;

  ngOnInit(): void {
    this.rnd = Math.round(Math.random()*10000);
    this.update();
    this.sub = this.eventQueue.on(AppEventType.UpdatePlaylist).subscribe(event => {
      this.update();

      console.log(event);

      if (event.payload != null) {
        window.setTimeout(function () {
          var trackObj = document.getElementById("track" + event.payload);
          var x = 0.5;
          var y = 0.5;
          if (trackObj != null) {
            var rect = trackObj.getBoundingClientRect();
            x = (rect.left + rect.width / 2) / document.body.clientWidth;
            y = (rect.top + rect.height) / document.body.clientHeight;
            new confetti({origin: {x: x, y: y}, startVelocity: 15, ticks: 70});
          }
        }, 300);
      }
    });
    this.checkInterval = window.setInterval(this.updateTrackTime.bind(this), 500);
  }

  ngOnDestroy(): void {
    window.clearInterval(this.checkInterval);
    this.sub.unsubscribe();
  }

  trackBy(index: number, track: Track) {
    return track.trackId;
  }
  transform(index: number) {
    return `translateY(${(index + 1) * 100}%)`;
  }

  updateTrackTime(): void {
    if (this.tracks == null) {
      return;
    }
    var now = new Date().getTime();
    var currentTrackTime = 0;
    for (var i=0; i<this.tracks.length; i++) {
      var track = this.tracks[i];
      if (currentTrackTime == 0) {
        if (track.currentlyPlaying != 2) {
          currentTrackTime = track.startTimestamp;
        } else {
          currentTrackTime = now - track.duration_ms + track.startTimestamp;
          currentTrackTime -= track.duration_ms;
          track.progress = track.startTimestamp / track.duration_ms;
        }
      }
      if (track.currentlyPlaying == 1) {
        track.progress = (now - track.startTimestamp) / track.duration_ms;
        //console.log("Progress is "+track.progress);
        if ((track.duration_ms - (now - track.startTimestamp) < 2000) &&
            (this.skipping != track.trackId) && !this.useSpotifyAsPlayer) {
          console.log("Progress is "+track.progress+" rnd: "+this.rnd+" Skipping track "+track.trackId);
          // Less than 1s? Skip this track.
          this.skipping = track.trackId;
          this.api.adminTrackCommand(track.trackId, 2).subscribe(x => { });
        }
      } else {
        currentTrackTime += track.duration_ms;
        var t = new Date(currentTrackTime);
        track.startTime = t.toLocaleTimeString([], {timeStyle: 'short'});
      }
    }
  }

  updatePartyStatus(): void {
    this.api.adminCheck().subscribe(x => {
      if (x == null) {
        return;
      }
      if (this.firstTime) {
        this.firstTime = false;
        this.api.adminHealthCheck().subscribe(y => {
          alert(y);
        });
      }
      this.partyStatus = x;
      if (this.partyStatus.device == null) {
        this.api.adminGetDevices().subscribe(y => {
          this.devices = y;
        });
      }
      console.log("Status: ",x);
    });
  }

  update(): void {
    if (this.sessionService.currentParty != null) {
      this.joinCode = this.sessionService.currentParty.joinCode;
      this.qrDestination = window.location.origin+"/?c="+this.joinCode;
    }
    this.iAmAdmin = this.sessionService.currentParty.iAmAdmin;
    this.useSpotifyAsPlayer = this.sessionService.currentParty.playlist != this.sessionService.currentParty.partyLeader;
    this.updatePartyStatus();

    this.fetchTracks();
    this.api.getVotes().subscribe(x => {
      if (x == null) {
        return;
      }
      this.ownVotes = {};
      for (var i=0; i<x.length; i++) {
        this.ownVotes[x[i].trackId] = x[i].vote;
      }
    });
    this.updateTrackTime();
  }

  fetchTracks() : void {
      this.api.getPlaylist().subscribe(x => {
          this.tracks = x;
          this.updateTrackTime();
      });
  }

  vote(id: string, direction: number): void {
    this.api.voteForTrack(id, direction).subscribe(x => {});
  }

  adminTrackCommand(id: string, command: number): void {
    this.loading = true;
    this.api.adminTrackCommand(id, command).subscribe(x => {
      this.loading = false;
    });
  }

  logout(): void {
    this.loading = true;
    this.api.logout().subscribe(x => {
      this.loading = false;
      window.location.href = "/";
    });
  }

  toggleQR() : void {
    this.qrExpanded = !this.qrExpanded;
  }
  selectDevice(id: string): void {
    this.loading = true;
    this.api.adminRegisterDevice(id).subscribe(x => {
      this.loading = false;
      this.sessionService.checkCurrentSession();
      this.eventQueue.dispatch(new AppEvent(AppEventType.UpdatePlaylist, null));
      this.updatePartyStatus();
      location.reload();
    });
  }

  reload(): void {
    location.reload();
  }

}
