import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PlaylistSelectionComponent } from './playlist-selection/playlist-selection.component';
import { PlaylistViewComponent } from './playlist-view/playlist-view.component';
import { TrackSearchComponent } from './track-search/track-search.component';

import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { TrackComponent } from './track/track.component';
import { ResponseInterceptor } from './ResponseInterceptor';
import { QRCodeModule } from 'angularx-qrcode';
import { PlayerComponent } from './player/player.component';
import { I18nModule } from './i18n.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PlaylistSelectionComponent,
    PlaylistViewComponent,
    TrackSearchComponent,
    TrackComponent,
    PlayerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    QRCodeModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    I18nModule.setLocale(), I18nModule.setLocaleId()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
