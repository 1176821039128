declare var confetti: any;
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { delay, filter, finalize, map, Observable, tap } from 'rxjs';
import { EventQueueService } from './event-queue.service';
import { AppEvent, AppEventType } from './models/appEvent';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(private eventQueue: EventQueueService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (event.type == 4 && event.body != null) {
                  if (event.body.action == "updatePlaylist") {
                    this.eventQueue.dispatch(new AppEvent(AppEventType.UpdatePlaylist, event.body.track));
                  }
                }
            })
        );
    }

}
