<!--
<br><p i18n="@@Playlist.Select">Search for a track:</p><br>
<div *ngFor="let playlist of playlists" class="possiblePlaylist">
    <button (click)="selectPlaylist(playlist.id)">{{playlist.name}} ({{playlist.tracks}} <span i18n="@@Playlist.Tracks">Create</span>)</button>
</div>
<br><p *ngIf="playlists.length == 0" i18n="@@Playlist.None">No playlist</p><br>

<button (click)="createPlaylist()"><span i18n="@@Playlist.Create">Create</span></button>
-->
Please wait
