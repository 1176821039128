///  <reference types="@types/spotify-web-playback-sdk"/>
declare var confetti: any;
import {Component, OnInit, Renderer2} from '@angular/core';
import { ApiService } from '../api.service';
import { EventQueueService } from '../event-queue.service';
import { AppEventType } from '../models/appEvent';
import { SessionService } from '../session.service';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent implements OnInit {

  constructor(
    private api: ApiService,
    private sessionService: SessionService,
    private eventQueue: EventQueueService,
    private renderer: Renderer2
    ) { }

  iAmAdmin : boolean = false;
  showPlayerInitialization : boolean = false;
  playerInitialized : boolean = false;
  player?: Spotify.Player;
  doingInit : boolean = false;
  error: boolean = false;
  playerLoaded: boolean = false;

  ngOnInit(): void {
    this.update();
    this.eventQueue.on(AppEventType.UpdatePlaylist).subscribe(event => {
      this.update();
    });
  }


  update(): void {
    this.iAmAdmin = this.sessionService.currentParty.iAmAdmin;
    if (this.iAmAdmin && !this.playerInitialized && this.sessionService.currentParty.playlist == this.sessionService.currentParty.partyLeader) {
      this.showPlayerInitialization = true;
      if (!this.playerLoaded) {
        window.onSpotifyWebPlaybackSDKReady = function () {};
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://sdk.scdn.co/spotify-player.js';
        this.renderer.appendChild(document.body, script);
        this.playerLoaded = true;
      }
    }
  }


  initializeSpotifyPlayer() : void {
    this.playerInitialized = true;
    this.doingInit = true;
    this.error = false;
    var doInit = () => {
      const token = this.sessionService.currentParty.accessToken;
      console.log("Have a party with token "+token);
      const player = new Spotify.Player({
        name: 'VoteToPlay',
        getOAuthToken: cb => { cb(token); },
        volume: 0.5
      });
      // Ready
      player.addListener('ready', ({ device_id }) => {
        console.log('Ready with Device ID', device_id);
        this.api.adminRegisterDevice(device_id).subscribe(x => {
          if (x.toString() == "1") {
            console.log(x);
            this.showPlayerInitialization = false;
            this.doingInit = false;
            new confetti({origin: {x: 0.5, y: 0.5}});
          } else {
            console.error("There was some error during device registration: "+x);
            this.showPlayerInitialization = true;
            this.error = true;
          }
        });
      });

      // Not Ready
      player.addListener('not_ready', ({ device_id }) => {
        console.log('Device ID has gone offline', device_id);
      });
      player.addListener('initialization_error', ({ message }) => {
        console.error(message);
        this.showPlayerInitialization = true;
        this.error = true;
      });

      player.addListener('authentication_error', ({ message }) => {
          console.error(message);
      });

      player.addListener('account_error', ({ message }) => {
          console.error(message);
      });

      player.connect().then(x => {
        console.log(x);
        if (!x) {
          window.location.href = "/api/login/host";
        }
      }, y => {
        this.showPlayerInitialization = false;
        console.log(y);
        this.doingInit = false;
      });

    };
    if (typeof(Spotify) != "undefined") {
      console.log("Instantly doing spotify init. Was already there.");
      doInit();
    } else {
      window.onSpotifyWebPlaybackSDKReady = doInit;
    }

}

}
