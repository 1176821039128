import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SessionService} from '../session.service';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    public sessionService: SessionService) {
  }

  joinCode = "";
  error = "";
  loading = false;
  imprint = false;
  hostError = false;

  ngOnInit(): void {
    this.hostError = false;
    this.route.queryParams
      .subscribe(params => {
          // Did someone scan the QR code and can be directly logged in?
          this.joinCode = params['c'];
          if (this.joinCode != "" && this.joinCode != null) {
            this.join();
          }
          // Hosting didn't work? Display some error.
          var someError = params['error'];
          if (someError == "host") {
            this.hostError = true;
          }
        }
      );
  }

  join(): void {
    console.log("join with code " + this.joinCode);
    this.error = "";
    this.loading = true;
    var x = this.http.get<Object>("/api/login/join?code=" + this.joinCode);
    x.subscribe((y) => {
      this.loading = false;
      if (y == "0") {
        this.error = "Party not found.";
        console.log("This is an error.");
      } else {
        this.sessionService.checkCurrentSession();
      }
    }, (y) => {
      this.loading = false;
    });
  }

  host(): void {
    window.location.href = "/api/login/host";
    this.loading = true;
  }

  toggleImprint(): void {
    this.imprint = !this.imprint;
  }

}
