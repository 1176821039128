import { SpotifyImage } from "./spotifyImage.model";

export class Track {
    id?: any;
    uri: string = "";
    artist: string = "";
    title: string = "";
    album: string = "";
    trackId: string = "";
    voteSum  : number = 0;
    votes: number = 0;
    added?: number;
    image: string = "";
    startTimestamp: number = 0;
    duration_ms: number = 0;
    popularity: number = 0;
    currentlyPlaying: number = 0;
    progress: number = 0.5;
    startTime: string = "";
  }
