<div class="trackSearch">
    <div class="header">
      <span i18n="@@Search.Track">Search for a track:</span><br>
        <input type="text" [(ngModel)]="currentInput" (keyup)="updateSearch()">
        <button [routerLink]="['/party']" class="closeButton">
            <img src="assets/close.svg">
        </button>
        </div>
  <div *ngIf="searchResult != null && searchResult.length > 0">
    <div *ngFor="let track of searchResult" class="playlistTrack">
      <app-track [track]="track"></app-track>
      <div class="buttonContainer">
        <button (click)="addTrack(track.id)" i18n="@@Add.Track">Add</button>
      </div>
    </div>
  </div>
  <div *ngIf="currentInput.length == 0 && recommendations.length > 0">
    <p i18n="@@Recommendations">These here are just recommendations. You can search for your own tracks by entering something in the input field above.</p>
    <div *ngFor="let track of recommendations" class="playlistTrack">
      <app-track [track]="track"></app-track>
      <div class="buttonContainer">
        <button (click)="addTrack(track.id)" i18n="@@Add.Track">Add</button>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="screenOverlay">
    <img src="assets/loading.png" class="animated loading">
  </div>
</div>
