/**
 * Holds a reference to the currently joind party (or not).
 * Also responsible for keeping the long polling connection alive.
 */
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { PartySession } from './models/partySession.model';
import { Router } from '@angular/router';
import {AppEvent, AppEventType} from "./models/appEvent";
import {EventQueueService} from "./event-queue.service";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(private api: ApiService, private router: Router, private eventQueue: EventQueueService) {
    this.currentParty = new PartySession();
    this.currentParty.joined  = false;
    this.currentParty.checked = false;
  }

  currentParty : PartySession;
  longPollCounter : number = 0;

  ngOnInit(): void {
    this.checkCurrentSession();
  }

  checkCurrentSession(): void {
    this.api.getSession().subscribe((y) => {
      this.currentParty.checked = true;
      if (y != null) {
        this.currentParty = y;
        this.currentParty.joined = true;
        if (this.currentParty.playlist != "") {
          this.router.navigate(["party"]);
          this.ensureLongPolling();
        } else {
          this.router.navigate(["playlistSelection"]);
        }
      } else {
        this.currentParty.joined = false;
      }
    });
  }

  ensureLongPolling(): void {
    var myself = this;
    if (myself.longPollCounter > 0) {
      return;
    }
    myself.longPollCounter++;
    this.api.longPolling().subscribe(x => {
      // Ok, got something back. Poll again.
      if (x != null && x == "exit") {
        console.log("Party has ended");
        window.location.href = "/";
        return;
      }
      myself.longPollCounter--;
      this.ensureLongPolling();
    }, y => {
      // An error occured. Either client was offline or server was offline,
      // so try again in a second.
      this.eventQueue.dispatch(new AppEvent(AppEventType.UpdatePlaylist, null));

      myself.longPollCounter--;
      window.setTimeout(this.ensureLongPolling.bind(this), 1000);
    });
  }


}
