<h2 class="title" i18n="@@App.Title">Vote To Play</h2>
<a id="spotifyLink" href="https://www.spotify.com" target="_blank"><img src="assets/Spotify_Logo_RGB_Green.png" /></a>
<div>
  <p i18n="@@App.Description">
    Vote To Play is a free Spotify-based site that lets every party guest vote on tracks to add to the playlist.
  </p>
</div>
<div>
  <span i18n="@@App.JoinPartyText">Join a party:</span><br>
  <input type="number" size=6 name="code" [(ngModel)]="joinCode" class="code">
    <button (click)="join()" i18n="@@App.JoinButton">Join</button>
    <div *ngIf="error != ''" class="error" i18n="@@App.JoinError">Party not found</div>
</div>
<div>
    <button (click)="host()" i18n="@@App.HostText">Host/resume a party</button><br>
  <span i18n="@@App.HostRequirement">(This requires a Spotify Premium account)</span>
</div>
<div *ngIf="loading" class="screenOverlay">
  <img src="assets/loading.png" class="animated loading">
</div>
<div *ngIf="imprint" class="screenOverlay">

</div>
<div *ngIf="hostError" class="screenOverlay">
  <button (click)="hostError = false;" class="closeButton">
    <img src="assets/close.svg">
  </button>
  <p i18n="@@App.HostError">Something went wrong in the communications with spotify. It is REQUIRED to have a premium account there to use this app. If you have a premium account you may just try again.</p>
</div>
